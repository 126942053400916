/**
 * 文件服务器接口
 */
import axiosApi from '../../modules/axios-api'
import { MAIN_MICRO_SERVICE_PREFIX } from '../../index'
// 文件上传
export function fileUpload(data) {
  return axiosApi.post(`${ MAIN_MICRO_SERVICE_PREFIX }/oss/upload`, data)
}
// 文件下载
export function fileDownload(params) {
  return axiosApi.get(`${ MAIN_MICRO_SERVICE_PREFIX }/oss/download`, params)
}
// 文件删除
export function fileDel(params) {
  return axiosApi.get(`${ MAIN_MICRO_SERVICE_PREFIX }/minio/deleteObjects`, params)
}
